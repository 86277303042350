import React, { FC, PropsWithChildren, useState } from 'react';

import { useWidthWindowSize } from '../../hooks/useWidthWindowSize';
import { useAppSelector } from '../../store/hooks';
import FilterLayout from './filterLayout/filterLayout';
import FilterItem from './filterItem/filterItem';
import Button from '../button/button';
import './filter.scss';

interface FilterProps {

}

const Filter: FC<PropsWithChildren<FilterProps>> = () => {
	const { filters } = useAppSelector(state => state.wine);
	const widthWindow = useWidthWindowSize();
	const isMobile = widthWindow && widthWindow <= 1023;

	const [openFilterLayout, setOpenFilterLayout] = useState(false);

	if (isMobile) {
		return (
			<div className='filter__layout-btn'>
				<Button
					type="medium"
					text='Refine your search'
					onClick={() => setOpenFilterLayout(prevState => !prevState)}
				/>
				<FilterLayout
					show={openFilterLayout}
					onClose={() => setOpenFilterLayout(false)}
				/>
			</div>
		)
	}

	return (
		<div className='filter'>
			{filters && Object.entries(filters).map(([search, filters], index) => (
				<FilterItem
					key={index}
					search={search}
					filters={filters}
				/>
			))}
		</div>
	);
};

export default Filter;