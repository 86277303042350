import React, { FC } from 'react';

import { useAppSelector } from '../../../store/hooks';
import SearchItem from '../search-item/search-item';
import './search-list.scss';


const SearchList: FC = () => {
	const {searchList} = useAppSelector(state => state.search);

	return (
		<>
			{searchList.length > 0 && (
				<div className='search__list'>
					{searchList.map((item, index) => (
						<SearchItem
							key={`${item.producer || '_'}${item.name || '_'}`}
							item={item}
							index={index}
						/>
					))}
				</div>
			)}
		</>

	);
};

export default SearchList;