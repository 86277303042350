import React, { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import BackButton from '../button/back-button/backButton';
import './header.scss';

interface HeaderProps {
	backText?: string
}

const Header: FC<PropsWithChildren<HeaderProps>> = ({backText}) => {
	return (
		<>
			<div className='header'>
				<h1 className='header__title'><Link to='/'>WEINE SUCHEN</Link></h1>
			</div>
			{backText && <BackButton text={backText}/>}
		</>
	);
};

export default Header;