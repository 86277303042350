import React, { FC, PropsWithChildren } from 'react';

import Icon from '../icon/icon';
import './arrowSlider.scss';

interface IArrowSliderProps {
	type: 'left' | 'right'
	onClick: () => void
}

const ArrowSlider: FC<PropsWithChildren<IArrowSliderProps>> = ({type, onClick}) => {
	return (
		<div className={`arrow-slider arrow-slider--${type}`} onClick={onClick}>
			<Icon type='rectangle' fill='none' height='20' width='12'/>
		</div>
	);
};

export default ArrowSlider;