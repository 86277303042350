import { useEffect, useState } from 'react';

export const useDeviceTouchScreen = () => {
	const [isTouch, setIsTouch] = useState<boolean>(false);

	useEffect(() => {
		if ('ontouchstart' in window || navigator.maxTouchPoints) {
			setIsTouch(true);
		}
	}, [window, navigator])

	return isTouch;
}