export const SearchCountries = {
	de: 'Deutschland',
	at: 'Österreich',
	ch: 'Schweiz',
	us: 'USA',
	gb: 'Großbritannien',
}

export const SearchMessages = {
	mandatory: 'Bitte fülle mindestens ein Feld aus :)',
	already: 'Dieser Wein befindet sich bereits in der Liste'
}