import React, { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import Icon from '../../icon/icon';
import './backButton.scss';

interface BackButtonProps {
	text?: string
}


const BackButton: FC<PropsWithChildren<BackButtonProps>> = ({text = 'back'}) => {
	return (
		<div className='back-button'>
			<Link to='/' className='back-button__link'>
				<Icon type='chevron_left' size='20' fill='none'/>
				<span>{text}</span>
			</Link>
		</div>
	);
};

export default BackButton;