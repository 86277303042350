import { IMerchant, IWine, IWineMatch } from '../types/wines.interface';
import { IWineFiltersWithState } from '../types/filters.interface';


const applyArray = ( array: {[key: string]: boolean}[] ) => {
	return array.map(item => {
		const [key, value] = Object.entries(item)[0];
		if (value) {
			return key;
		}
		return null;
	}).filter(item => item !== null);
};


const checkMatch = (shops: IMerchant[]) => {
	let flag = false;

	if (!shops || shops.length === 0) {
		return flag;
	}

	shops.forEach(item => {
		Object.values(item.wines).forEach(array => {
			if (array.length > 0) {
				flag = true;
			}
		})
	})

	return flag;
}

export const usefilterWines = (wines: IWineMatch, filters: IWineFiltersWithState) => {
	const filteredWines: IWineMatch = {};

	 Object.entries(wines).forEach(([match, shops]) => {

		const parsedShops: IMerchant[] = shops.map(item => {

			const shopWines = item.wines;

			const parsedShopWines: {[key: string]: IWine[]} = {};

			Object.entries(shopWines).forEach(([filter, wines]) => {

				const applyYears = applyArray(filters[filter].year);
				const applyLitres = applyArray(filters[filter].litres);
				const applyProducers = applyArray(filters[filter].producers);


				const parsedWineItems: IWine[] = wines.filter(item => {

					const flagYear = applyYears.includes(String(item.vintage));
					const flagLitres =  applyLitres.includes(String(item.volume));
					const flagProducers = applyProducers.includes(String(item.producer?.name));

					return (flagLitres && flagYear && flagProducers);
				})

				parsedShopWines[filter] = parsedWineItems;
			})

			return {
				name: item.name,
				amountWines: item.amountWines,
				wines: {...parsedShopWines}
			}
		})

	 	filteredWines[match] = parsedShops;
	});


	 let isMultipleEmpty = checkMatch(filteredWines.multipleMatch);
	 let isMiddleEmpty = checkMatch(filteredWines.middleMatch);
	 let isSingleEmpty = checkMatch(filteredWines.singleMatch);

	 const isWinesEmpty = isMultipleEmpty || isMiddleEmpty || isSingleEmpty;

	return {
		isWinesEmpty,
		filteredWines
	}
}