import { ISearchWines } from './search.interface';
import { API } from '../../utils/axios';

export const SearchService = {
	async fetchWine (wine: ISearchWines, country: string) {

		try {
			const {name, producer} = wine;

			const body = {query: { name, producer }, country };

			const { data } = await API.post<{found: number}>('/wine', body);

			return data.found;

		} catch (e) {
			console.log(e);
			return 0;
		}
	}
}