import React, { FC, PropsWithChildren, useEffect } from 'react';
import ReactDOM from 'react-dom'

import './modal.scss';

interface IModalProps {
	show: boolean
}

const Modal: FC<PropsWithChildren<IModalProps>> = ({show, children}) => {

	useEffect(() => {
		if (show) {
			document.body.style.overflow = 'hidden';
		}

		return () => {
			document.body.style.overflow = 'unset';
		}
	}, [show])

	if (!show) return null;

	return ReactDOM.createPortal(
		<div className='modal'>
			{children}
		</div>,
		document.getElementById('portal') as HTMLElement
	);
};

export default Modal;