import { useEffect, useState } from 'react';

export const useWidthWindowSize = () => {
	const [windowWidth, setWindowWidth] = useState<number>();
	useEffect(() => {
		function handleResize() {
			if (/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)) {
				setWindowWidth(document.documentElement.clientWidth);
			} else {
				setWindowWidth(window.innerWidth);
			}
		}

		window.addEventListener("resize", handleResize);

		handleResize();

		return () => window.removeEventListener("resize", handleResize);
	}, []);
	return windowWidth;
}