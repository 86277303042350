import React, { FC, useState, KeyboardEvent, PropsWithChildren, useEffect, useRef } from 'react';

import { changeSearchProducer } from '../../../store/search/search.slice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { SearchMessages } from '../search.constans';
import { API } from '../../../utils/axios';
import './searchProducerAutocomplete.scss';

interface ISearchProducer {
	errorMessage: keyof typeof SearchMessages | null
	setErrorMessage: (value: keyof typeof SearchMessages | null) => void
}

const SearchProducerAutocomplete: FC<PropsWithChildren<ISearchProducer>> = ({errorMessage, setErrorMessage}) => {
	const dispatch = useAppDispatch();
	const { searchWine } = useAppSelector(state => state.search);

	const [options, setOptions] = useState<string[]>([]);

	const [showOptions, setShowOptions] = useState(false);

	const [inputItemIndexFocus, setInputItemIndexFocus] = useState(-1);

	const inputRef = useRef<HTMLInputElement>(null);
	useClickOutside(inputRef, () => {
		setShowOptions(false);
		setErrorMessage(null);
	});

	useEffect(() => {
		if (searchWine.producer === '') {
			setOptions([]);
		}
	}, [searchWine]);

	const handleInputItemFocus = async (e: KeyboardEvent<HTMLInputElement>) => {
		switch (e.key) {
			case 'ArrowDown':
				if (inputItemIndexFocus < options.length - 1) {
					setInputItemIndexFocus(prevState => prevState + 1)
				} else {
					setInputItemIndexFocus(0)
				}
				break;

			case 'ArrowUp':
				if (inputItemIndexFocus > 0) {
					setInputItemIndexFocus(prevState => prevState - 1)
				} else {
					setInputItemIndexFocus(options.length - 1)
				}
				break;

			case 'Enter':
			case 'NumpadEnter':
				if (inputItemIndexFocus !== -1) {
					await handleInputChange(options[inputItemIndexFocus]);
				}
				setInputItemIndexFocus(-1);
				setOptions([])
				break;
		}
	}

	const handleInputChange = async (value: string) => {
		setErrorMessage(null);
		dispatch(changeSearchProducer(value));
		if (value.trim().length >= 3) {
			const {data} = await API.get(`/producer/${value.trim()}`);
			setShowOptions(true)
			setOptions(data);
			return;
		}
		setOptions([]);
	}

	const handleClickChange = async ( value: string ) => {
		await handleInputChange(value);
		setShowOptions(false);
	}

	return (
		<div className='search-producer' ref={inputRef}>
			<input
				className={`search-producer__input ${errorMessage ? 'search-producer__input--error' : ''}`}
				type="text"
				placeholder='Winzer'
				value={searchWine.producer}
				onChange={(e) => handleInputChange(e.target.value)}
				onKeyDown={handleInputItemFocus}
				onFocus={() => setShowOptions(true)}
				onBlur={() => setErrorMessage(null)}
			/>

			{errorMessage && <div className='search-producer__message'>{SearchMessages[errorMessage]}</div>}

			{options.length > 0 && showOptions && (
				<div
					className='search-producer__options'
					onMouseMove={() => setInputItemIndexFocus(-1)}
				>
					{options.map((item, index) => (
						<div
							key={index}
							className={`search-producer__item ${index === inputItemIndexFocus ? 'search-producer__item--focused' : ''}`}
							onClick={() => handleClickChange(item)}
						>
							<span>{item}</span>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default SearchProducerAutocomplete;