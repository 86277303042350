import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { addSearchWine, changeSearchName } from '../../store/search/search.slice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchSearchWinesIfRequired } from '../../store/search/search.thunks';
import { resetWineState } from '../../store/wine/wine.slice';
import { SearchMessages } from './search.constans';
import { WinesService } from '../../store/wine/wines.service';
import SearchProducerAutocomplete from './search-producer/searchProducerAutocomplete';
import SearchCountry from './search-country/search-country';
import SearchList from './search-list/search-list';
import Button from '../button/button';
import Icon from '../icon/icon';
import './search-control.scss';


const SearchControl: FC = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const { searchWine, searchList, country } = useAppSelector(state => state.search);

	const [errorMessage, setErrorMessage] = useState<keyof typeof SearchMessages | null>(null);

	const [isWinesFetching, setIsWinesFetching] = useState(false);

	useEffect(() => {
		dispatch(resetWineState())
		dispatch(fetchSearchWinesIfRequired());
	}, []);

	useEffect(() => {
		const someWineFetching = searchList.some(item => item.loading);
		setIsWinesFetching(someWineFetching);
	}, [searchList])

	const handleSearchWines = () => {
		const params = WinesService.createWinesUrlParams(searchList, country);
		navigate(`/wines?${params}`, {state: { fromMainPage: true }});
	};

	const handleAddSearchWine = () => {
		const { producer, name } = searchWine;

		if (!producer && !name) {
			setErrorMessage('mandatory');
			return;
		}

		const isItemAlreadyInList = searchList
			.map(item => ({ producer: item.producer?.trim().toLowerCase(), name: item.name?.trim().toLowerCase() }))
			.some(item => item.producer === producer?.trim().toLowerCase() && item.name === name?.trim().toLowerCase()
		);

		if (isItemAlreadyInList) {
			setErrorMessage('already');
			return;
		}

		dispatch(addSearchWine());
		dispatch(fetchSearchWinesIfRequired());
	}

	return (
		<div className='search'>
			<div className='search__info'>
				<div className='search__info--header'>Weine suchen leicht gemacht</div>
				<div className='search__info--about'>
					<p>Liste deine gewünschten Weine unten auf und wir werden 300 Wein Shops online durchsuchen, um deine Auswahl zu finden.</p>
					<p>Falls es jedoch dazu kommen sollte, dass einer oder mehrere deiner Weine nicht auffindbar sind, ist dieser entweder ausgetrunken, oder du schaust noch mal nach der korrekten Schreibweise. Viel Spaß beim Weine suchen :)</p>
				</div>
			</div>

			<div className='search__controls'>

				{/*<SearchCountry/>*/}

				<SearchProducerAutocomplete
					errorMessage={errorMessage}
					setErrorMessage={setErrorMessage}
				/>

				<input
					className={`search__wine-name ${errorMessage ? 'search__wine-name--error' : ''}`}
					type='text'
					placeholder='Weinname'
					value={searchWine.name}
					onChange={(e) => dispatch(changeSearchName(e.target.value))}
				/>

				<Button type='medium' color='reverse' onClick={handleAddSearchWine}>
					<Icon type='plus' size='20'/>
					<span>Hinzufügen</span>
				</Button>

			</div>

			<SearchList />

			{searchList.length > 0 &&
				<div className='search__btn'>
					<div className='search__btn--text'>
						<span>Wir durchsuchen 300 Weinshops, um Ihre Weine zu finden</span>
					</div>
					<Button
						type='medium'
						text='Weine suchen'
						disabled={isWinesFetching}
						onClick={handleSearchWines}/>
				</div>
			}
		</div>
	);
};

export default SearchControl;