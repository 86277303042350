export const iconList = {
	chevron_left:
		<path d="M13.5 1.66669L5.16669 10L13.5 18.3334" stroke="#222222" strokeWidth="2"/>,
	expand:
		<>
			<line x1="11.7999" y1="2.40001" x2="11.7999" y2="21.6" stroke="#222222" strokeWidth="2"/>
			<line x1="2.40002" y1="12.2" x2="21.6" y2="12.2" stroke="#222222" strokeWidth="2"/>
		</>,
	collapse:
		<line x1="2.40002" y1="12.2" x2="21.6" y2="12.2" stroke="#222222" strokeWidth="2"/>,
	plus:
		<>
			<line x1="10" y1="2" x2="10" y2="18" stroke="#222222" strokeWidth="2"/>
			<line x1="2" y1="10" x2="18" y2="10" stroke="#222222" strokeWidth="2"/>
		</>,
	cross:
		<>
			<line x1="8.95991" y1="10.3733" x2="27.0618" y2="28.4752" stroke="#222222" strokeWidth="2"/>
			<line x1="9.80852" y1="28.4752" x2="27.9105" y2="10.3733" stroke="#222222" strokeWidth="2"/>
		</>,
	rectangle:
		<path d="M1.50004 18.3333L9.83337 10L1.50004 1.66668" stroke="black" strokeWidth="2"/>,
	edit:
		<path d="M6.82353 15.2928L4.70711 13.1764L13.1765 4.7071L15.2929 6.82351L6.82353 15.2928ZM4.50006 14.3836L5.61646 15.4999L4.50012 15.5L4.50006 14.3836Z" stroke="#222222"/>,
	delete:
		<>
			<path d="M3 4.82666H17" stroke="#222222" strokeMiterlimit="10"/>
			<path d="M11.8612 5.19665V2.67529H8.15527V5.19665" stroke="#222222" strokeMiterlimit="10"/>
			<path d="M10 6.94336V14.5887" stroke="#222222" strokeMiterlimit="10"/>
			<path d="M12.7168 6.94336V14.5887" stroke="#222222" strokeMiterlimit="10"/>
			<path d="M7.29102 6.94336V14.5887" stroke="#222222" strokeMiterlimit="10"/>
			<path d="M4.83594 4.82666L5.76927 16.6752H14.205L15.1383 4.82666" stroke="#222222" strokeMiterlimit="10"/>
		</>,
	check:
		<path d="M3 11.2941L7.26087 15L17 5" stroke="#222222"/>,
	wine: <>
		<g clipPath="url(#clip0_359127_2689)">
			<path id='spinner-path'/>
			<path d="M44.354 1.36353L46.547 26.4075C47.4242 36.6106 41.8046 46.6227 32.1552 50.1692C29.6058 51.1241 26.8645 51.6151 24.0136 51.6151C21.1626 51.6151 18.4214 51.0968 15.872 50.1692C6.22261 46.6227 0.575552 36.6106 1.45277 26.4348L3.61839 1.36353H44.354Z" stroke="#222222" strokeWidth="3" strokeMiterlimit="10"/>

			<path d="M23.9863 52.925V88.6359" stroke="#222222" strokeWidth="3" strokeMiterlimit="10"/>
			<path d="M10.3894 88.636H37.5556" stroke="#222222" strokeWidth="3" strokeMiterlimit="10"/>
		</g>
		<defs>
			<clipPath id="clip0_359127_2689">
				<rect width="48" height="90" fill="white"/>
			</clipPath>
		</defs>
	</>,
	emptyWine:
		<>
			<g clipPath="url(#clip0_359069_2251)">
				<path d="M83.1716 0.5L83.9712 9.68278C84.291 13.4239 82.2422 17.095 78.7242 18.3954C77.7947 18.7455 76.7953 18.9256 75.7559 18.9256C74.7165 18.9256 73.717 18.7355 72.7876 18.3954C69.2696 17.095 67.2108 13.4239 67.5306 9.69278L68.3201 0.5H83.1716Z" stroke="#222222" strokeMiterlimit="10"/>
				<path d="M75.7441 19.4058V32.4997" stroke="#222222" strokeMiterlimit="10"/>
				<path d="M70.7871 32.4998H80.6914" stroke="#222222" strokeMiterlimit="10"/>
				<path d="M67.5293 10.5032H81.2814" stroke="#222222" strokeMiterlimit="10"/>
			</g>
			<g clipPath="url(#clip1_359069_2251)">
				<path d="M36.7421 32.5C32.6986 32.5 28.7202 31.2873 25.3065 29.0128C21.7445 26.63 17.587 25.3571 13.3294 25.3571L0.5 25.3571L0.499999 20.6429L13.3156 20.6429C17.5735 20.6429 21.7307 19.3699 25.3061 16.9875C28.72 14.7128 32.6847 13.5 36.7421 13.5L57.5 13.5L57.5 32.5L36.7421 32.5Z" stroke="#222222" strokeMiterlimit="10"/>
				<path d="M40.9141 28.7141L40.9141 12.9998" stroke="#222222" strokeMiterlimit="10"/>
			</g>
			<defs>
				<clipPath id="clip0_359069_2251">
					<rect width="17.5" height="33" fill="white" transform="translate(67)"/>
				</clipPath>
				<clipPath id="clip1_359069_2251">
					<rect width="20" height="58" fill="white" transform="translate(0 33) rotate(-90)"/>
				</clipPath>
			</defs>
		</>
}