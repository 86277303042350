import React, { FC, PropsWithChildren } from 'react';

import { useAppSelector } from '../../../store/hooks';
import { IWine } from '../../../types/wines.interface';
import ShopWine from '../shop-wine/shopWine';
import './shopItem.scss';

interface ShopItemProps {
	type: 'multiple' | 'middle' | 'single'
	name: string
	wines: {
		[key: string]: IWine[]
	}
}

const ShopItem: FC<PropsWithChildren<ShopItemProps>> = ({ type, name, wines }) => {
	const { searchList } = useAppSelector(state => state.search);

	const totalNumberOfParameters = searchList.length;
	const numbersOfMatches = Object.values(wines).filter(item => item.length).length;

	const shopWines = Object.entries(wines).map(([search, wines], index) => {
		if (!wines.length) {
			return null;
		}
		return <ShopWine key={index} search={search} wines={wines}/>
	})

	const isShopItemEmpty = shopWines.every(element => element === null);

	return (
		<>
			{!isShopItemEmpty && (
				<div className='shop-item'>
					<div className='shop-item__header'>
						<a className='shop-item__name' href={'//' + name} target='_blank' rel='noreferrer'>{name}</a>
						<div className={`shop-item__found shop-item__found--${type}`}>
							{`${numbersOfMatches} von ${totalNumberOfParameters} Weinen gefunden`}
						</div>
					</div>

					<div className='shop-item__main'>
						{shopWines}
					</div>
				</div>
			)}
		</>
	);
};

export default ShopItem;