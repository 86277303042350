import React, { FC } from 'react';

import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import './imprintPage.scss';

const ImprintPage: FC = () => {
	return (
		<>
			<Header backText='zurück zur Suche'/>

			<div className='imprint'>
				<h1>Impressum</h1>

				<h2>Angaben gemäß § 5 TMG</h2>
				<h3>Patrick Zimmermann</h3>
				<p>
					Westendstr. 149 <br/>
					80339 München
				</p>

				<h2>Kontakt</h2>
				<p>E-Mail: impressum@weine-suchen.com</p>

				<h2>EU-Streitschlichtung</h2>
				<p>
					Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a
					href="https://ec.europa.eu/consumers/odr/." target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/.</a> <br/>
					Unsere E-Mail-Adresse finden Sie oben im Impressum. <br/>
					Verbraucherstreitbeilegung/Universalschlichtungsstelle <br/>
					Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
				</p>
			</div>

			<Footer/>
		</>
	);
};

export default ImprintPage;