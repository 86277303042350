import React, { FC, PropsWithChildren } from 'react';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { changeFilter } from '../../../store/wine/wine.slice';
import './checkboxFilter.scss';


interface CheckboxProps {
	index: number
	search: string
	type: 'year' | 'litres' | 'producers'
	text: string | number
}

const CheckboxFilter: FC<PropsWithChildren<CheckboxProps>> = ({index, search, type, text}) => {
	const dispatch = useAppDispatch();
	const { filters } = useAppSelector(state => state.wine);

	const isChecked = filters[search][type][index][text];

	const handleChangeInput = () => {
		dispatch(changeFilter({index, search, filter: type, key: text}))
	}

	return (
		<label className='checkbox'>
			<input className='checkbox__input' type="checkbox" checked={isChecked} onChange={handleChangeInput}/>
			<div className='checkbox__indicator'/>
			<span>{text}</span>
		</label>
	);
};

export default CheckboxFilter;