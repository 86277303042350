import React, { FC, PropsWithChildren } from 'react';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { applyFilters, cancelFilters } from '../../../store/wine/wine.slice';
import FilterItem from '../filterItem/filterItem';
import Button from '../../button/button';
import Modal from '../../modal/modal';
import Icon from '../../icon/icon';
import './filterLayout.scss';

interface FilterLayoutProps {
	show: boolean;
	onClose: () => void;
}

const FilterLayout: FC<PropsWithChildren<FilterLayoutProps>> = ({ show, onClose }) => {
	const dispatch = useAppDispatch();

	const { filters } = useAppSelector(state => state.wine);

	const handleApplyFilters = () => {
		dispatch(applyFilters());
		onClose();
	}

	const handleCancelFilters = () => {
		dispatch(cancelFilters());
		onClose();
	}

	return (
		<Modal show={show}>
			<div className='filter-layout'>
				<div className='filter-layout__head'>
					<span>Refine your search</span>
					<div className='filter-layout__close' onClick={handleCancelFilters}><Icon type='cross' size='37'/></div>
				</div>
				<div className='filter-layout__main'>
					<div className='filter'>
						{filters && Object.entries(filters).map(([search, filters], index) => (
							<FilterItem
								key={index}
								search={search}
								filters={filters}
							/>
						))}
					</div>
				</div>
				<div className='filter-layout__apply'>
					<Button type='small' color='reverse' text='Abbrechen' onClick={handleCancelFilters}/>
					<Button type='small' text='Anwenden' onClick={handleApplyFilters}/>
				</div>
			</div>
		</Modal>
	);
};

export default FilterLayout;