import React, { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { changeWineInSearchList, deleteSearchWine } from '../../../store/search/search.slice';
import { fetchSearchWinesIfRequired } from '../../../store/search/search.thunks';
import { ISearchWines } from '../../../store/search/search.interface';
import Icon from '../../icon/icon';
import './search-item.scss';

interface ISearchItemProps {
	item: ISearchWines
	index: number
}

const SearchItem: FC<PropsWithChildren<ISearchItemProps>> = ({item, index}) => {
	const dispatch = useAppDispatch();
	const { searchList } = useAppSelector(state => state.search);

	const editItemRef = useRef<HTMLInputElement>(null);

	const [value, setValue] = useState(`${item.producer}${item.name ? ` - ${item.name}` : ''}`);
	const [editMode, setEditMode] = useState(false);

	useEffect(() => {
		if (editMode) {
			editItemRef.current && editItemRef.current.focus()
		}
	}, [editMode])


	const handleDoneSetValue = () => {
		if (!value) {
			dispatch(deleteSearchWine(index));
			return
		}

		let [producer, name] = value.split('-');
		producer = producer.trim();
		if (name) {
			name = name.trim();
		} else {
			name = '';
		}
		dispatch(changeWineInSearchList({id: index, producer, name, found: null, loading: false}));
		dispatch(fetchSearchWinesIfRequired());
		setEditMode(false);
	}

	return (
		<div className='search-item'>
			<div className='search-item--name'>

				<input
					value={value}
					onChange={(e) => setValue(e.target.value)}
					onBlur={handleDoneSetValue}
					disabled={!editMode}
					ref={editItemRef}
				/>
			</div>

			{!editMode
				? <>
					<div className='search-item--count'>
						{searchList[index].loading || searchList[index].found === null
							? <span className='count-loading'>Suchen...</span>
							: <span className={`count-${searchList[index].found ? 'result' : 'empty'}`}>
								{searchList[index].found === 0
									? 'Anzahl: 0'
									: `Anzahl: ${searchList[index].found}`}
							</span>
						}
					</div>

					<button onClick={() => setEditMode(true)}>
						<Icon type='edit' size='20' fill='none'/>
						<span>Bearbeiten</span>
					</button>

					<button onClick={() => dispatch(deleteSearchWine(index))}>
						<Icon type='delete' size='20' fill='none'></Icon>
						<span>Löschen</span>
					</button>
				</>
				: <button
					className='search-item__done'
					onClick={handleDoneSetValue}
				>
					<Icon type='check' size='20' fill='none'/>
					<span>done</span>
				</button>
			}
		</div>
	);
};

export default SearchItem;