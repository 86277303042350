import React, { FC, PropsWithChildren, useState } from 'react';

import CheckboxFilter from '../../checkbox/checkboxFilter/checkboxFilter';
import Icon from '../../icon/icon';
import './filterItem.scss';

interface FilterItemProps {
	search: string,
	filters: {
		year: {[key: string | number]: boolean}[]
		litres: {[key: string | number]: boolean}[]
		producers: {[key: string | number]: boolean}[]
	}
}

const FilterItem: FC<PropsWithChildren<FilterItemProps>> = ({ search, filters }) => {
	const [isItemOpen, setIsItemOpen] = useState(false);

	const [name, producer] = search.split('--');

	const parsedName = name !== '_' ? name : null;
	const parsedProducer = producer !== '_' ? producer : null;

	const {year, litres, producers} = filters;

	const isWineFound = Object.entries(year).length && Object.entries(litres);

	return (
		<div className='filter-item'>
			<div className='filter-item__head'>

				{ parsedProducer
					? <div className='filter-item__producer'>{parsedProducer}</div>
					: <div className='filter-item__name'>- {parsedName}</div>
				}

				{ isWineFound
					? (
						<div className={`filter-item__switch filter-item__switch--${isItemOpen ? 'open' : 'close'}`}
							 onClick={() => setIsItemOpen(!isItemOpen)}
						>
							<Icon size='24' type={isItemOpen ? 'collapse' : 'expand'} />
						</div>
					)
					: <div className='filter-item__not-found'>Anzahl: 0</div>
				}
			</div>

			{ parsedProducer && (
				<div className='filter-item__name'>
					{parsedName ? `- ${parsedName}` : '-'}
				</div>
			)}

			{isItemOpen && year && Object.entries(year).length > 0 && (
				<>
					<div className='filter-item__property'>Jahrgang</div>
					<div className='filter-item__container'>

						{Object.entries(year).map(([key, value], index) => (
							<div className='filter-item__subproperty' key={index}>
								<CheckboxFilter type='year' search={search} text={Object.keys(value)[0]} index={index} />
							</div>
						))}
					</div>
				</>
			)}

			{isItemOpen && litres && Object.entries(litres).length > 0 && (
				<>
					<div className='filter-item__property'>Liter</div>
					<div className='filter-item__container'>

						{Object.entries(litres).map(([key, value], index) => (
							<div className='filter-item__subproperty' key={index}>
								<CheckboxFilter type='litres' search={search} text={Object.keys(value)[0]} index={index} />
							</div>
						))}
					</div>
				</>
			)}

			{isItemOpen && producers && Object.entries(producers).length > 0 && (
				<>
					<div className='filter-item__property'>Winzer</div>
					<div className='filter-item__container filter-item__container--line'>

						{Object.entries(producers).map(([key, value], index) => (
							<div className='filter-item__subproperty filter-item__subproperty--line' key={index}>
								<CheckboxFilter type='producers' search={search} text={Object.keys(value)[0]} index={index} />
							</div>
						))}
					</div>
				</>
			)}
		</div>
	);
};

export default FilterItem;