import * as qs from 'qs';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { setSearchListFromParsedUrl } from '../search/search.slice';
import { WinesService } from './wines.service';
import { setFilter } from './wine.slice';
import { RootState } from '../hooks';


export const fetchWines = createAsyncThunk<any, undefined, {state: RootState}>(
	'wine/fetchWines',
	async (_, {dispatch, getState, rejectWithValue}) => {
		try {
			const { searchList, country } = getState().search;

			const wines = await WinesService.getWines(searchList, country);

			if (!wines) {
				return rejectWithValue(null);
			}

			const filters = WinesService.createWineFilters(searchList, wines);

			dispatch(setFilter(filters));

			return wines;

		} catch (e) {
			console.log(e);
		}
	}
)

export const fetchWinesFromUrl = createAsyncThunk<any, string, {state: RootState}>(
	'wine/fetchWinesFromUrl',
	async (searchUrl, {dispatch}) => {
		try {

			const searchParams = qs.parse(searchUrl.slice(1));

			dispatch(setSearchListFromParsedUrl(searchParams));

			dispatch(fetchWines());

		} catch (e) {
			console.log(e);
		}
	}
)