import React, { FC, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

import { resetSearchList } from '../../store/search/search.slice';
import { resetWineState } from '../../store/wine/wine.slice';
import { useAppDispatch } from '../../store/hooks';
import Button from '../button/button';
import Icon from '../icon/icon';
import './emptySearch.scss';

const EmptySearch: FC<PropsWithChildren> = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const handleNewSearchButton = () => {
		dispatch(resetWineState());
		dispatch(resetSearchList());
		navigate('/')
	}

	return (
		<div className='empty-search'>
			<div className='empty-search__img'>
				<Icon type='emptyWine' width='85' height='33' fill='none'/>
			</div>
			<div className='empty-search__text'>
				<p>Leider konnten wir keine Weine finden, die deinen Suchkriterien entsprechen. Vielleicht möchtest du es noch mal versuchen?</p>
				<Button type='medium' text='Neue Suche' onClick={handleNewSearchButton} />
			</div>
		</div>
	);
};

export default EmptySearch;