import React, { FC } from 'react';

import { ReactComponent as SpinnerFrame1 } from '../../assets/spinner/spinner_frame_1.svg'
import { ReactComponent as SpinnerFrame2 } from '../../assets/spinner/spinner_frame_2.svg'
import { ReactComponent as SpinnerFrame3 } from '../../assets/spinner/spinner_frame_3.svg'
import { ReactComponent as SpinnerFrame4 } from '../../assets/spinner/spinner_frame_4.svg'
import { ReactComponent as SpinnerFrame5 } from '../../assets/spinner/spinner_frame_5.svg'
import './spinner.scss';

const Spinner: FC = () => {

	return (
		<div className='spinner'>
			<div>
				<SpinnerFrame1/>
				<SpinnerFrame2/>
				<SpinnerFrame3/>
				<SpinnerFrame4/>
				<SpinnerFrame5/>
			</div>
			<span>Ergebnisse werden eingegossen....</span>
		</div>
	)
};


export default Spinner;