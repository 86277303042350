import React, { FC, PropsWithChildren, SyntheticEvent, useEffect, useRef, useState } from 'react';

import { useDeviceTouchScreen } from '../../../hooks/useDeviceTouchScreen';
import { useWidthWindowSize } from '../../../hooks/useWidthWindowSize';
import { useAppSelector } from '../../../store/hooks';
import { IWine } from '../../../types/wines.interface';
import ImageError from '../../../assets/wine_not_found.svg'
import ArrowSlider from '../../arrowSlider/arrowSlider';
import './shopWine.scss';

interface ShopWineProps {
	search: string
	wines: IWine[]
}

const ShopWine: FC<PropsWithChildren<ShopWineProps>> = ({ search, wines }) => {
	const {filters} = useAppSelector(state => state.wine);
	const widthWindow = useWidthWindowSize();
	const isScreenTouch = useDeviceTouchScreen()

	const containerRef = useRef<HTMLDivElement>(null);

	const [name, producer, year] = search.split('--');

	const parsedName = name !== '_' ? name : null;
	const parsedProducer = producer !== '_' ? producer : null;
	const parsedVintage = year !== '_' ? year : null;

	const [currentSlide, setCurrentSlide] = useState(0);
	const [maxSlides, setMaxSlider] = useState<number>();

	useEffect(() => {
		setCurrentSlide(0);

		if (containerRef.current) {
			const clientWidth = containerRef.current.clientWidth;
			const countSlides = wines.length - Math.floor(clientWidth / 350);
			setMaxSlider(countSlides >= 0 ? countSlides : 0);
		}
	}, [wines, filters, widthWindow]);

	const handleSlideNext = () => {
		if (currentSlide !== maxSlides) {
			setCurrentSlide(prevState => prevState + 1);
		}
	};

	const handleSlidePrevious = () => {
		if (currentSlide !== 0) {
			setCurrentSlide(prevState => prevState - 1);
		}
	};

	const handleImageError = (e: SyntheticEvent<HTMLImageElement>) => {
		e.currentTarget.onerror = null;
		e.currentTarget.src = ImageError;
	};

	return (
		<>
			{wines.length > 0 && (
				<div className='wine-item'>
					<div className='wine-item__search'>
						<div>{parsedProducer}</div>
						<div className='wine-item__search--second'>
							{parsedName && `- ${parsedName}`} {parsedVintage && `- ${parsedVintage}`}
							{(!parsedName && !parsedVintage) && '-'}
						</div>
					</div>
					<div className={`wine-item__container ${isScreenTouch ? 'wine-item__container--touch' : ''}`} ref={containerRef}>
						{!isScreenTouch && currentSlide !== 0 && <ArrowSlider type='left' onClick={handleSlidePrevious}/>}
						{!isScreenTouch && currentSlide !== maxSlides &&  <ArrowSlider type='right' onClick={handleSlideNext}/>}

						{wines.map((item, index) => (
							<a
								key={index}
								className='wine-item__product'
								href={item.link}
								target='_blank'
								rel='noreferrer'
								style={index === 0 ? {marginLeft: `-${currentSlide * 350}px`} : {}}
							>
								<div className='wine-item__img'>
									<img src={item.imageUrl ?? ImageError} alt={item.name} loading='lazy' onError={handleImageError} />
								</div>
								<div className='wine-item__info'>
									<div className='wine-item__name'><span>{item.name}</span></div>
									<div className='wine-item__producer'>{item.producer?.name}</div>
									<div className='wine-item__characteristics'>
										<div>{item.vintage ?? '-'}</div>
										<div>{item.volume ? item.volume + 'l' : '-'}</div>
										<div>{item.price ? `${item.price.toFixed(2)}€` : '-'}</div>
									</div>
								</div>
							</a>
						))}
					</div>
				</div>
			)}
		</>
	);
};

export default ShopWine;