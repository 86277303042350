import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IActionChangeFilter, IWineState } from './wine.interface';
import { fetchWines } from './wine.thunks';
import { IWineMatch } from '../../types/wines.interface';


const initialState: IWineState = {
	fetchWineStatus: 'idle',
	wines: {},
	filters: {},
	previousFilters: {}
}

export const WineSlice = createSlice({
	name: 'wine',
	initialState,
	reducers: {
		setFilter: (state, action) => {
			state.filters = action.payload;
			state.previousFilters = action.payload;
		},

		changeFilter: (state, action: PayloadAction<IActionChangeFilter>) => {
			const {search, filter, index, key} = action.payload

			const previous = state.filters[search][filter][index][key];

			state.filters[search][filter][index][key] = !previous;
		},

		resetWineState: (state) => {
			state.fetchWineStatus = 'idle';
			state.wines = {};
			state.filters = {};
		},

		applyFilters: (state) => {
			state.previousFilters = state.filters;
		},

		cancelFilters: (state) => {
			state.filters = state.previousFilters;
		}
	},

	extraReducers: builder => {
		builder
			.addCase(fetchWines.pending, (state) => {
				state.fetchWineStatus = 'loading';
			})
			.addCase(fetchWines.fulfilled, (state, action: PayloadAction<IWineMatch>) => {
				state.fetchWineStatus = 'fetched';
				state.wines = action.payload;
			})
			.addCase(fetchWines.rejected, (state) => {
				state.fetchWineStatus = 'error';
			})

			// .addCase(fetchWinesFromUrl.pending, (state) => {
			// 	state.fetchWineStatus = 'loading';
			// })
			// .addCase(fetchWinesFromUrl.fulfilled, (state, action: PayloadAction<IWineMatch>) => {
			// 	state.fetchWineStatus = 'fetched';
			// 	state.wines = action.payload;
			// })
			// .addCase(fetchWinesFromUrl.rejected, (state) => {
			// 	state.fetchWineStatus = 'idle';
			// })
	}
})

export const {
	setFilter,
	resetWineState,
	changeFilter,
	applyFilters,
	cancelFilters
} = WineSlice.actions;

export default WineSlice.reducer;