import { FC } from 'react';

import SearchControl from '../../components/search-control/search-control';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import './main.scss';

const MainPage: FC = () => {

	return (
		<>
			<Header />

			<div className='main'>
				<SearchControl/>
			</div>

			<Footer/>
		</>

	)
}

export default MainPage;