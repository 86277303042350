import React, { FC, PropsWithChildren, useEffect, useState } from 'react';

import { IWineMatch } from '../../types/wines.interface';
import ShopItem from './shop-item/shopItem';
import Button from '../button/button';
import './shopResult.scss';


interface IShopResult {
	wines: IWineMatch
}

const ShopResult: FC<PropsWithChildren<IShopResult>> = ({ wines }) => {

	const [isShowSingleMatch, setIsShowSingleMatch] = useState<boolean>(false);

	const isSingleMatch = Boolean(wines.singleMatch?.length);

	useEffect(() => {
		if (!wines.multipleMatch?.length && !wines.middleMatch?.length) {
			setIsShowSingleMatch(true);
		}
	}, []);

	return (
		<div className='shop-result'>
			{wines?.multipleMatch && wines.multipleMatch.map((item, index) =>
				<ShopItem key={index} type='multiple' name={item.name} wines={item.wines} />
			)}

			{wines?.middleMatch && wines.middleMatch.map((item, index) =>
				<ShopItem key={index} type='middle' name={item.name} wines={item.wines} />
			)}

			{!isShowSingleMatch && isSingleMatch && (
				<div className='shop-result__pagination'>
					<Button type='small' text='Mehr anzeigen' onClick={() => setIsShowSingleMatch(true)} />
				</div>
			)}

			{isShowSingleMatch && isSingleMatch && wines?.singleMatch && wines.singleMatch.map((item, index) =>
				<ShopItem key={index} type='single' name={item.name} wines={item.wines} />
			)}
		</div>
	);
};

export default ShopResult;