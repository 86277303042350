import * as qs from 'qs';

import { IWineFilters, IWineFiltersWithState } from '../../types/filters.interface';
import { ISearchUrlParams, ISearchWines } from '../search/search.interface';
import { IWineMatch } from '../../types/wines.interface';
import { API } from '../../utils/axios';

export const WinesService = {
	async getWines(searchWines: ISearchWines[], country: string) {
		try {
			const params = this.createWinesUrlParams(searchWines, country);

			const { data } = await API.get<IWineMatch>(`/wine?${params}`);

			const wasDataFound = Object.values(data).some(item => item.length > 0);

			if (wasDataFound) {
				return data;
			}
			return null

		} catch (e) {
			console.log(e);
			return null;
		}
	},

	createWineFilters (searchList: ISearchWines[], wines: IWineMatch | undefined) {
		if (!wines) {
			return ;
		}

		const filters: IWineFilters = this.createFilterSkeleton(searchList);

		for (let shops of Object.values(wines)) {

			shops.forEach(item => {

				for (let [search, wines] of Object.entries(item.wines)) {

					if (!filters[search]) {
						console.error('The filter was not defined in time');

						filters[search] = {
							year: [],
							litres: [],
							producers: []
						}
					}

					for (let item of wines) {
						if (!filters[search].year.includes(item.vintage)) {
							filters[search].year.push(item.vintage);
						}

						if (!filters[search].litres.includes(item.volume)) {
							filters[search].litres.push(item.volume);
						}

						if (!filters[search].producers.includes(item.producer?.name)) {
							filters[search].producers.push(item.producer?.name);
						}

					}
					filters[search].year.sort((a, b) => b - a);
					filters[search].litres.sort((a, b) => b - a);
				}
			})
		}

		const filterWithState: IWineFiltersWithState = {};

		Object.entries(filters).forEach(([key, value]) => {
			filterWithState[key] = {year: [], litres: [], producers: []};

			Object.entries(value).forEach(([filter, items]) => {
				// @ts-ignore
				filterWithState[key][filter] = items.map(item => ({[item]: true}));
			})
		})

		return filterWithState
	},

	createFilterSkeleton (searchList: ISearchWines[]) {
		const filters: IWineFilters = {};

		searchList.forEach(item => {
			const key = this.createKeyWineQuery(item.name, item.producer);

			filters[key] = {
				year: [],
				litres: [],
				producers: []
			};
		});

		return filters
	},

	createKeyWineQuery (name: string, producer: string) {
		const keyName = (name !== '' && name.replace('--', '').trim()) || '_';
		const keyProducer = (producer !== '' && producer.replace('--', ' ').trim()) || '_';

		return `${keyName}--${keyProducer}`;
	},

	createWinesUrlParams (searchWines: ISearchWines[], country: string) {
		const parsedSearchWines = searchWines.map(item => {
			return {
				...(item.producer) && { producer: item.producer },
				...(item.name) && { name: item.name }
			}
		});

		const parsedUrl = {
			country,
			queries: parsedSearchWines
		};

		return qs.stringify(parsedUrl);
	},

	isWinesUrlValid (urlString: string): Boolean {
		if (!urlString) {
			return false
		}

		const parsedUrl: ISearchUrlParams = qs.parse(urlString.slice(1));

		return !!(parsedUrl.country && parsedUrl.queries && parsedUrl.queries.length > 0);
	}
}