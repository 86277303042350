import React, { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import './footer.scss';


const Footer: FC<PropsWithChildren> = () => {


	return (
		<div className='footer'>
			<div className='footer__links'>
				<div className='footer__link'><Link to='/imprint'>Impressum</Link></div>
				<div className='footer__divider'/>
				<div className='footer__link'><Link to='/protection'>Datenschutzerklärung</Link></div>
			</div>
		</div>
	);
};

export default Footer;