import { FC } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import MainPage from '../pages/main/main';
import WinesPage from '../pages/wines/wines';
import ProtectionPage from '../pages/protection/protectionPage';
import ImprintPage from '../pages/imprint/imprintPage';

const AppRouter: FC = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path='/' element={<MainPage/>}/>
				<Route path='/wines' element={<WinesPage/>}/>
				<Route path='/protection' element={<ProtectionPage/>}/>
				<Route path='/imprint' element={<ImprintPage/>}/>
				<Route path='*' element={<Navigate to='/'/>}/>
			</Routes>
		</BrowserRouter>
	)
}

export default AppRouter;