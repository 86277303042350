import { createAsyncThunk } from '@reduxjs/toolkit';

import { changeFoundFieldWine, changeStatusItemSearchWine } from './search.slice';
import { SearchService } from './search.service';
import { RootState } from '../hooks';


export const fetchSearchWinesIfRequired = createAsyncThunk<any, undefined, {state: RootState}>(
	'search/fetchSearchWinesIfRequired',
	async (_, {dispatch, getState}) => {
		try {
			const {searchList, country} = getState().search;

			let flagFirstEntry = true

			for (let index = 0; index < searchList.length; index ++) {
				const item = searchList[index];

				if (item.found !== null) {
					continue;
				}

				if (flagFirstEntry) {
					flagFirstEntry = false
				} else {
					await new Promise((resolve) => setTimeout(resolve, 1000));
				}

				dispatch(changeStatusItemSearchWine({index, status: true}));

				const result = await SearchService.fetchWine(searchList[index], country);

				if (result || result === 0) {
					dispatch(changeFoundFieldWine({id: index, count: result}));
				}

				dispatch(changeStatusItemSearchWine({index, status: false}));
			}

		} catch (e) {
			console.log('Error', e)
		}
	}
)