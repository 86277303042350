import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ISearchState, ISearchUrlParams, ISearchWines } from './search.interface';
import { SearchCountries } from '../../components/search-control/search.constans';


const initialState: ISearchState = {
	country: 'de',
	searchWine: { name: '', producer: '' },
	searchList: [],
	producerError: false
}

export const searchSlice = createSlice({
	name: 'search',
	initialState,
	reducers: {

		changeSearchName: (state, action: PayloadAction<string>) => {
			state.searchWine.name = action.payload
		},

		changeSearchProducer: (state, action: PayloadAction<string>) => {
			state.searchWine.producer = action.payload
		},

		changeSearchCountry: (state, action: PayloadAction<keyof typeof SearchCountries>) => {
			state.country = action.payload;
		},

		addSearchWine: (state => {
			const name = state.searchWine.name.trim();
			const producer = state.searchWine.producer.trim();

			state.searchList.unshift( { name, producer, found: null, loading: false } );

			state.searchWine = {name: '', producer: ''}
		}),

		changeWineInSearchList: (state, action: PayloadAction<{id: number} & ISearchWines>) => {
			const {id, name, producer, found, loading} = action.payload;
			state.searchList[id] = {name, producer, found, loading};
		},

		changeFoundFieldWine: (state, action: PayloadAction<{id: number, count: number | null}>) => {
			const {id, count} = action.payload;
			state.searchList[id].found = count;
		},

		deleteSearchWine: (state, action: PayloadAction<number>) => {
			const index = action.payload;

			state.searchList.splice(index, 1);
		},

		changeStatusItemSearchWine: (state, action: PayloadAction<{index: number, status: boolean}>) => {
			const {index, status} = action.payload;

			state.searchList[index].loading = status;
		},

		deleteCountForAllSearchList: (state) => {
			state.searchList = state.searchList.map(item => {
				return {
					...item,
					found: null
				}
			})
		},

		resetSearchList: (state) => {
			state.searchList = []
		},

		setSearchListFromParsedUrl: (state, action: PayloadAction<ISearchUrlParams>) => {
			const { country, queries } = action.payload;

			if (country && queries) {
				state.country = country;

				state.searchList = queries.map(item => {
					return {
						producer: item.producer ?? '',
						name: item.name ?? '',
						found: null,
						loading: false
					}
				});
			}
		}
	}
})

export const {
	addSearchWine,
	changeSearchName,
	changeSearchProducer,
	changeSearchCountry,
	changeWineInSearchList,
	changeStatusItemSearchWine,
	changeFoundFieldWine,
	deleteSearchWine,
	deleteCountForAllSearchList,
	resetSearchList,
	setSearchListFromParsedUrl
} = searchSlice.actions;
export default searchSlice.reducer;