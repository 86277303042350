import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchWines, fetchWinesFromUrl } from '../../store/wine/wine.thunks';
import { resetWineState } from '../../store/wine/wine.slice';
import { resetSearchList } from '../../store/search/search.slice';
import { usefilterWines } from '../../hooks/useFilterWines';
import { WinesService } from '../../store/wine/wines.service';
import EmptySearch from '../../components/emptySearch/emptySearch';
import ShopResult from '../../components/shop-result/shopResult';
import Spinner from '../../components/spinner/spinner';
import Header from '../../components/header/header';
import Button from '../../components/button/button';
import Filter from '../../components/filter/filter';
import Footer from '../../components/footer/footer';
import ScrollToTop from '../../hocs/scrollToTop';
import './wines.scss';


const WinesPage: FC<PropsWithChildren> = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useAppDispatch();

	const { wines, fetchWineStatus, filters } = useAppSelector(state => state.wine);
	const { searchList } = useAppSelector(state => state.search);

	const {filteredWines, isWinesEmpty} = usefilterWines(wines, filters);

	useEffect(() => {
		// @ts-ignore
		const IsFromMainPage = location.state?.fromMainPage;

		const searchUrl = location.search;

		const isSearchParamsValid = WinesService.isWinesUrlValid(searchUrl);

		if (((IsFromMainPage && !searchList.length) || !IsFromMainPage) && isSearchParamsValid) {
			dispatch(fetchWinesFromUrl(searchUrl));
			return;
		}

		if (IsFromMainPage) {
			dispatch(fetchWines());
			return;
		}

		navigate('/');

	}, []);

	const handleNewSearchButton = () => {
		dispatch(resetWineState());
		dispatch(resetSearchList());
		navigate('/');
	}

	return (
		<ScrollToTop>
			<Header backText='zurück zur Suche'/>
				<div className='wines'>
					{fetchWineStatus === 'error' && <EmptySearch/>}
					{fetchWineStatus === 'loading' && <Spinner/>}
					{fetchWineStatus === 'fetched' && (
						<>
							<div className='wines__filter'>
								<div className='wines__title'>
									<h2 className='wines__title__text'>Du hast gesucht:</h2>
								</div>

								<Filter/>

							</div>
							<div className='wines__result'>

								{ isWinesEmpty
									? <>
										<div className='wines__title'>
											<div className='wines__title__head'>
												<h2 className='wines__title__text'>Wir haben gefunden:</h2>
												<Button type='small' text='Neue Suche' color='reverse' onClick={handleNewSearchButton}/>
											</div>
										</div>

										<ShopResult wines={filteredWines} />
									</>
									: <EmptySearch/>
								}
							</div>
						</>
					)}
				</div>
			<Footer/>
		</ScrollToTop>
	);
};

export default WinesPage;

// const [combineBestResult, setCombineBestResult] = useState<boolean>(false);

// {/*{wines.multipleMatch?.length === 0 && (*/}
// {/*	<Toggle*/}
// {/*		status={combineBestResult}*/}
// {/*		changeStatus={() => setCombineBestResult(!combineBestResult)}*/}
// {/*		text='combine shops for the best result'*/}
// {/*	/>*/}
// {/*)}*/}